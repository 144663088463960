<template>
  <Toast/>
  <div v-if="telemedicina && !medicoOnline">
    <div style="display: flex; align-items: center; justify-content: center; height: 100vh; flex-direction: column;">
      <ProgressSpinner />

      <h5>Médico não está na sala. Aguarde...</h5>
    </div>

  </div>
  <div v-if="telemedicina && medicoOnline" ref="jitsiContainer" style="margin: 14px; height: 85vh"></div>
  <div v-if="telemedicina && medicoOnline && medicoAssinou && !pacienteAssinou" style="margin: 14px">
    <Button style="" label="Assinar documento" class="p-button-primary w-full" @click="assinarPaciente" />
  </div>
  <div v-if="!telemedicina" style="margin: 14px; height: 85vh">Não foi possível carregar!</div>
</template>

<script>
import { getClientBase } from '@/services/http'

export default {
  data() {
    return {
      telemedicina: null,
      medicoOnline: false,
      medicoAssinou: false,
      pacienteAssinou: false,
    }
  },
  async mounted () {
    const responseQuestionario = await getClientBase().get('/paciente-resposta-questionario/atendimento/1');
    if (responseQuestionario.data.length === 0) {
      this.$router.push(`/questionario-paciente/${this.$route.params.sala}`);
      return;
    }
    const { data } = await getClientBase().get(`/telemedicinas/by-nome-sala/${this.$route.params.sala}`)
    if (data) {
      this.iniciaTeleconsulta()
    }
    this.telemedicina = { ...data };
    this.medicoOnline = this.telemedicina.onlineMedico;

    if (this.telemedicina.assinadoDigitalmentePaciente && this.telemedicina.assinadoEletronicamentePaciente) {
      this.pacienteAssinou = true;
    }

    this.socketMedicoContectado();
    this.socketMedicoAssinou();
  },
  beforeUnmount () {
    this.removeJitsiWidget();
  },
  methods: {
    socketMedicoContectado() {
      this.$socket.on('paciente.medico-conectado', (data) => {
        if (data.telemedicinaId === this.telemedicina.id) {
          if (this.telemedicina) {
            if (data.conectado) {
              this.iniciaTeleconsulta()
            }
            this.medicoOnline = data.conectado
          }
        }
      });
    },
    socketMedicoAssinou() {
      this.$socket.on('paciente.medico-assinou', (data) => {
        if (data.telemedicinaId === this.telemedicina.id) {
          this.medicoAssinou = true;
        }
      });
    },
    async assinarPaciente() {
      getClientBase().post(`/telemedicinas/${this.telemedicina.id}/assinatura-paciente`);
      this.pacienteAssinou = true;
      this.$toast.add({ severity: 'success', summary: 'ASO assinada com sucesso!', life: 3000 });
    },
    iniciaTeleconsulta() {
      this.loadScript('https://meet.jit.si/external_api.js', () => {
        if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
          this.embedJitsiWidget();
        });
    },
    loadScript (src, cb) {
      const scriptEl = document.createElement('script');
      scriptEl.src = src;
      scriptEl.async = 1;
      document.querySelector('head').appendChild(scriptEl);
      scriptEl.addEventListener('load', cb);
    },
    embedJitsiWidget () {
      const domain = 'meet.jit.si';
      const options = {
        roomName: this.telemedicina.nomeSala,
        parentNode: this.$refs.jitsiContainer,
        configOverwrite: {
          disableDeepLinking: true,
          prejoinPageEnabled: false,
          disableLocalVideoFlip: true,
          doNotFlipLocalVideo: true,
          hideParticipantsStats: true,
          disableRemoteMute: true,
          disableRemoteControl: true,
          remoteVideoMenu: {
            disableKick: true,
            disableGrantModerator: true,
          },
          subject: 'Atendimento Telemedicina'},
        interfaceConfigOverwrite: {
          FILM_STRIP_MAX_HEIGHT: '0.1',
          SHOW_CHROME_EXTENSION_BANNER: false,
          DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
          LANG_DETECTION: true,
          VIDEO_QUALITY_LABEL_DISABLED: true,
          CONNECTION_INDICATOR_DISABLED: true,
          TOOLBAR_BUTTONS: ['microphone', 'camera','fullscreen', 'tileview', 'fullscreen']
        }
      };
      this.jitsiApi = new window.JitsiMeetExternalAPI(domain, options);
    },
    executeCommand (command, ...value) {
      this.jitsiApi.executeCommand(command, ...value);
    },
    addEventListener (event, fn) {
      this.jitsiApi.on(event, fn);
    },

    removeJitsiWidget () {
      if (this.jitsiApi) this.jitsiApi.dispose();
    },
  }
};
</script>
